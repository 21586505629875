import React, {useEffect, useState} from 'react';

import "./consultant.scss";

import {clsx} from 'clsx';

import {FiX} from 'react-icons/fi';
import moment from 'moment-timezone';
import { useNavigate, useNavigation, useLocation } from "react-router-dom";

function SingleConsultantPanel({
                                   item,
                                   consultantsDetails, placeholder, noPadding,
                                   selectable, cancelEvent, selectConsultantEvent, appointmentSummary, cancelable,
                                   appointment
                               }) {
    const [profileVisible, setProfileVisible] = useState(false);

    const [selectorItem, setSelectorItem] = useState(0);
    const [finalInformationVisible, setFinalInformationVisible] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.profile) {
            return;
        }

        setProfileVisible(false);
    }, [location]);

    let getPicture = gmc => {
        let founded = null;
        if (consultantsDetails) {
            for (let detail of consultantsDetails) {
                if (detail.GMCNumber === parseInt(gmc)) {
                    founded = detail;
                    break;
                }
            }
        }

        return founded;
    };

    const submitSelected = () => {
        hideProfile();
        setTimeout(() => {
            selectConsultant();
        }, 250);
    }

    const selectConsultant = () => {
        if (item.onlineBooking === "no") {
            setFinalInformationVisible(true);
        } else {
            if (!item.available) {
                setFinalInformationVisible(true);
            } else {
                if (selectConsultantEvent) {
                    selectConsultantEvent(item);
                }
            }
        }
    }

    const cancelled = () => {
        if (cancelEvent) {
            cancelEvent(appointment);
        }
    }

    let showProfile = () => {
        navigate("/?profile=1", {state: {profile: true}});
        setProfileVisible(true);
    };
    let hideProfile = () => {
        setProfileVisible(false);
        navigate(-1);
    };

    let replaceElement = (what, sign) => {
        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        const search = escapeRegExp(sign ? sign : ",");
        const searchRegExp = new RegExp(`(?:${search})`, 'ig');

        return what.replace(searchRegExp, "<br\>");
    };

    const formatDateSelected = hourOnly => {
        let m = moment(appointment.when);
        if (hourOnly) {
            return m.format("HH:mm");
        }
        return m.format("dddd Do MMMM, YYYY");
    }

    return (
        <div>
            {placeholder &&
                <div
                    className={clsx({"single-consultant-panel": true, "no-padding": true, "placeholder": true})}>
                    <div className={"consultant-panel-item1"}>
                        <div className={"empty-picture"}>
                            <div className={"empty-picture-img"}></div>
                        </div>
                    </div>
                    <div className={"consultant-panel-item2"}>
                    </div>
                </div>
            }

            <div className={"app-modal-message"} style={{
                display: finalInformationVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label info"}>
                            Please call us
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setFinalInformationVisible(false);
                            }}>
                                <img src={"/close2.png"} />
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        We apologise but it is not currently possible to book an appointment for this MyWay specialist via the online system.
                        <br/><br/>
                        Please call our bookings team on&nbsp;
                        <a className={"phone-call"} href="tel:08000928924"><b>0800 092 8924</b></a> who may be able to book your MyWay specialist appointment for you. If you would like to select an alternative MyWay specialist, <a className={"phone-call"} onClick={() => setFinalInformationVisible(false)}>please click here</a>.
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>

            {!placeholder && <div
                className={clsx({
                    "single-consultant-panel": true,
                    "no-padding": noPadding === true,
                    "cancelable": cancelable === true,
                    "single-consultant-unavailable": !appointmentSummary && !item.available && !item.onlineBooking === "no"
                })}>

                <div className={"consultant-buttons-parent"}>
                    {cancelable === true &&
                        <div className={"consultant-buttons cancelable"}>
                            <div className={"consultant-select-button"} onClick={cancelled}>
                                Cancel appointment
                            </div>
                        </div>
                    }
                    <div className={"consultant-buttons"}>
                        {getPicture(item.gmcNumberBase) === null && !cancelable &&
                            <div className={"consultant-profile-button disabled"}>
                                View profile
                            </div>
                        }
                        {getPicture(item.gmcNumberBase) !== null && !cancelable &&
                            <div className={"consultant-profile-button"} onClick={showProfile}>
                                View profile
                            </div>
                        }

                        <div className={"consultant-buttons-separator"}>
                        </div>
                        {selectable !== false &&
                            <div className={"consultant-select-button"} onClick={selectConsultant}>
                                Select
                            </div>
                        }
                    </div>
                </div>

                <div
                    className={clsx({
                        "flexi": true
                    })}>
                    {getPicture(item.gmcNumberBase) !== null &&
                        <div className={"consultant-panel-item1"}>
                            <div className={"consultant-image"}>
                                <div>
                                    <img className={"consultant-image-img"}
                                         src={`data:image/jpeg;base64,${getPicture(item.gmcNumberBase).imgData}`}/>
                                </div>
                            </div>
                        </div>
                    }
                    {getPicture(item.gmcNumberBase) === null &&
                        <div className={"consultant-panel-item1"}>
                            <div className={"consultant-image"}>
                                <div>
                                    <img className={"consultant-image-img"}
                                         src="/img_placeholder.png"/>
                                </div>
                                {/*<div className={"consultant-buttons"}>*/}
                                {/*    <div className={"consultant-profile-button disabled"}>*/}
                                {/*        View profile*/}
                                {/*    </div>*/}
                                {/*    <div className={"consultant-buttons-separator"}>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    }
                    <div className={"consultant-panel-item2"}>
                        {cancelable &&
                            <>
                                <div className={"consultant-panel-date"}>
                                    {formatDateSelected()}
                                </div>
                                <div className={"consultant-panel-hour"}>
                                    at {formatDateSelected(true)}
                                </div>
                            </>
                        }

                        <div className={"consultant-panel-full-name"}>
                            {item.fullName}
                        </div>
                        <div className={"consultant-panel-info-line1 with-spacing"}>
                            {item.speciality}
                        </div>
                        <div className={"consultant-panel-info-line2 with-spacing"}>
                            <div className={"consultant-panel-location"}>{item.location1}</div>
                            <div dangerouslySetInnerHTML={{__html: replaceElement(item.regionalSites)}}/>
                        </div>

                        <div className={"consultant-panel-info-line2"}>
                            {/*{selectable !== false &&*/}
                            {/*    <div className={"select-button"} onClick={selectConsultant}>*/}
                            {/*        Select*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {item.distance >= 0.0 &&
                                <div
                                    className="consultant-distance">Within: <b>{Math.round(item.distance * 100) / 100} miles</b>
                                </div>
                            }
                            {/*{item.distance < 0.0 &&*/}
                            {/*    <div*/}
                            {/*        className="consultant-distance"><i>Distance unknown</i>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>

                {/*<div className={"consultant-buttons"}>
                    {getPicture(item.gmcNumberBase) === null &&
                        <div className={"consultant-profile-button disabled"}>
                            View profile
                        </div>
                    }
                    {getPicture(item.gmcNumberBase) !== null &&
                        <div className={"consultant-profile-button"} onClick={showProfile}>
                            View profile
                        </div>
                    }

                    <div className={"consultant-buttons-separator"}>
                    </div>
                    {selectable !== false &&
                        <div className={"consultant-select-button"} onClick={selectConsultant}>
                            Select
                        </div>
                    }
                </div>*/}

            </div>
            }

            {profileVisible &&
                <div className={"profile-page"}>
                    <div className={"profile-top-label"}>
                        <div className={"close-button"} onClick={hideProfile}>
                            <FiX size={"24px"}/>
                        </div>
                        Consultant profile
                    </div>
                    <div className={"profile-top"}>
                        {getPicture(item.gmcNumberBase) !== null &&
                            <div className={"consultant-panel-items"}>
                                <div className={"consultant-panel-items-p1"}>
                                    <div className={"consultant-image"}>
                                        <div>
                                            <img className={"consultant-image-img"}
                                                 src={`data:image/jpeg;base64,${getPicture(item.gmcNumberBase).imgData}`}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"consultant-panel-items-p2"}>
                                    <div className={"consultant-panel-full-name"}>
                                        {item.fullName}
                                    </div>
                                    <div className={"consultant-panel-info-line1 with-spacing"}>
                                        {item.speciality}
                                    </div>
                                    <div className={"consultant-panel-info-line2 with-spacing"}>
                                        <div className={"consultant-panel-location"}>{item.location1}</div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: replaceElement(item.regionalSites)}}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={"profile-page-selector"}>
                        <div className={clsx({"selector-item": true, "selected": selectorItem === 0})}
                             onClick={() => {
                                 setSelectorItem(0)
                             }}>
                            Personal Profile
                        </div>
                        {getPicture(item.gmcNumberBase).articleClinicalInterests &&
                            <div className={clsx({"selector-item": true, "selected": selectorItem === 1})}
                                 onClick={() => {
                                     setSelectorItem(1)
                                 }}>
                                Clinical Interests
                            </div>
                        }
                        {getPicture(item.gmcNumberBase).articleProfessionalMemberships &&
                            <div className={clsx({"selector-item": true, "selected": selectorItem === 2})}
                                 onClick={() => {
                                     setSelectorItem(2)
                                 }}>
                                Professional Memberships
                            </div>
                        }
                    </div>

                    <div className={"profile-page-text"}>
                        {selectorItem === 0 && getPicture(item.gmcNumberBase).articlePersonalProfile &&
                            <div dangerouslySetInnerHTML={{
                                __html:
                                    replaceElement(getPicture(item.gmcNumberBase).articlePersonalProfile, "\n")
                            }}/>
                        }
                        {selectorItem === 1 && getPicture(item.gmcNumberBase).articleClinicalInterests &&
                            <div dangerouslySetInnerHTML={{
                                __html:
                                    replaceElement(getPicture(item.gmcNumberBase).articleClinicalInterests, "\n")
                            }}/>
                        }
                        {selectorItem === 2 && getPicture(item.gmcNumberBase).articleProfessionalMemberships &&
                            <div dangerouslySetInnerHTML={{
                                __html:
                                    replaceElement(getPicture(item.gmcNumberBase).articleProfessionalMemberships, "\n")
                            }}/>
                        }
                    </div>

                    {selectable &&
                        <div className={"profile-page-submit-button"} onClick={submitSelected}>
                            Select
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default SingleConsultantPanel;
