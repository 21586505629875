import React, {useEffect, useRef, useState} from 'react';

import "./mainPage.scss";
// import "../pages.scss";
import {ButtonBox} from "../button/buttons";
import {clsx} from 'clsx';
import Textbox from "../../components/textbox/textbox";
import _ from "lodash";
import SingleConsultantPanel from "../consultant/consultant";
import WeekCalendar from "../week_calendar/weekCalendar";
import MonthCalendar from "../week_calendar/monthCalendar";

import moment from 'moment-timezone';
import axios from "axios";
import config from "../../config";

import {FiCheck, FiCircle, FiCheckCircle, FiChevronLeft, FiChevronDown} from 'react-icons/fi';
import {FaCheckCircle, FaCircle} from 'react-icons/fa';

import { useNavigate, useNavigation, useLocation } from "react-router-dom";

function MainPage({
                      currentStage,
                      setParentCurrentStage, scrollIntoElement,
                      data, specialtySelectedChange, subSpecialitySelectedChange,
                      consultantSelectedChange, slotSelectedChange,
                      consultantAuthorization, refreshConsultants,
                      clearConsultants
                  }) {

    const [visibleSelector, setVisibleSelector] = useState("");
    const [currentPageTimesToDisplay, setCurrentPageTimesToDisplay] = useState(0);

    const [appointmentType, setAppointmentType] = useState(0);
    const [appointmentDistance, setAppointmentDistance] = useState(30);

    const [searchCriteriaChanged, setSearchCriteriaChanged] = useState(false);

    // const [currentStage, setCurrentStage] = useState(0);

    const [filterPostcodeTextValue, setFilterPostcodeTextValue] = useState(data.postalPatient);
    const [filterNameTextValue, setFilterNameTextValue] = useState("");
    const [filterTextValue, setFilterTextValue] = useState("");

    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedSubSpecialty, setSelectedSubSpecialty] = useState(null);
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [selectedSlotDateData, setSelectedSlotDateData] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);

    const [registrationAuthorizationCode, setRegistrationAuthorizationCode] = useState(null);
    const [selectorBoxVisible, setSelectorBoxVisible] = useState(false);
    const [selectedSearchTypeForConsultant, setSelectedSearchTypeForConsultant] = useState(1);

    const [finalInformationVisible, setFinalInformationVisible] = useState(false);

    const refStage2 = useRef(null);
    const refStage3 = useRef(null);
    const refStage4 = useRef(null);

    const navigate = useNavigate();

    // console.log("data.noSubSpecialty", data.noSubSpecialty);
    // console.log("data.freeSlots", data.freeSlots);

    /*
    useEffect(() => {
        setSearchCriteriaChanged(true);
    }, [appointmentType, appointmentDistance]);
    */

    const ref = useRef();

    useEffect(() => {
        // console.log("ref.current && currentStage > ref.current", ref.current, currentStage)
        if (ref.current && currentStage < ref.current) {
            if (currentStage === 0) {
                selectedSpecialtyCleared();
                // clearConsultants();
            }
            if (currentStage === 1) {
                setSearchCriteriaChanged(true);
                clearConsultants();
            }
        }
        // noinspection JSValidateTypes
        ref.current = currentStage;

        //
        switch (currentStage) {
            case 0:
                if (((!data.subSpecialities && !data.consultants) || (data.subSpecialities && data.subSpecialities.length > 0))) {
                    if (selectedSubSpecialty !== null) {
                        selectedSpecialtyCleared();
                    }
                } else {
                    selectedSpecialtyCleared();
                }
                scrollIntoElement(0);
                break;
            case 1:
                break;
            case 2:
                setSelectedConsultant(null);
                break;
            case 3:
                setSelectedSlotDateData(null);
                break;
            case 4:
                setSelectedSlot(null);
                break;
        }
    }, [currentStage]);

    // auto set sub specialty when there is only one ....
    useEffect(() => {
        if (data.subSpecialities && selectedSpecialty) {
            if (selectedSubSpecialty === null) {
                if (data.subSpecialities.length === 1) {
                    selectSubSpecialty(data.subSpecialities[0])();
                }
            }
        }

        if (data._noConsultants === true) {
            setFinalInformationVisible(true);
        }

        if (!filterPostcodeTextValue || filterPostcodeTextValue === "") {
            setFilterPostcodeTextValue(data.postalPatient);
        }
    }, [data]);

    const bookNow = () => {
        if (!!selectedSlot) {
            /*
            setVisibleSelector("book-now");
            showSelectorBox();
            */
            registrationConfirmed();
        }
    }

    const cancelConfirmation = () => {
        hideSelectorBox();
    }
    const confirmConfirmation = () => {
        // TODO:
        // alert("TODO!");
        registrationConfirmed();
    }

    const [messageBoxVisible, setMessageBoxVisible] = useState(false);
    const [postalCodeErrorVisible, setPostalCodeErrorVisible] = useState(false);

    let showErrorModal = retryButtonPresent => {
        setMessageBoxVisible(true);
    }

    let registrationConfirmed = () => {
        /*
        setRegistrationAuthorizationCode("TEST TEST");
        stageSwitch(6);
        // setVisibleSelector("confirmation");
        // showSelectorBox();
        return;
        */

        axios.post(`${config.api_host}/api/dict/appointment/register`, {
            jwt: window.jwtTest,
            selectedSlot: selectedSlot.identifier,
            // FIXME: remove below test ...
            // selectedSlot: "ABCD1",
            doctor: selectedConsultant.gmcNumber
        })
            .then(function (response) {
                if (response.data.ok === -1) {
                    // this is error as well
                    showErrorModal();
                } else {
                    // console.log("response.data.authorisation", response);
                    // console.log("response.data.ok", response.data.ok);
                    setRegistrationAuthorizationCode(response.data.authorisation);
                    stageSwitch(6);
                    // setVisibleSelector("confirmation");
                    // showSelectorBox();
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                showErrorModal();
            })
            .finally(function () {
                // always executed
            });
    };

    let getSpecs = () => {
        let specs = [];
        if (data && data.specialities) {
            _.forEach(data.specialities, s => {
                if (filterTextValue === "" || s.toLowerCase().includes(filterTextValue.toLowerCase())) {
                    specs.push(s);
                }
            });
        }
        return specs;
    };
    let getSubSpecs = () => {
        let specs = [];
        if (data && data.subSpecialities) {
            _.forEach(data.subSpecialities, s => {
                if (filterTextValue === "" || s.toLowerCase().includes(filterTextValue.toLowerCase())) {
                    specs.push(s);
                }
            });
        }
        return specs;
    };

    let getSlotHours = () => {
        let hours = [];
        if (selectedSlotDateData && selectedSlotDateData.availableHours) {
            _.forEach(selectedSlotDateData.availableHours, h => {
                hours.push(h);
            });
        }
        return hours;
    }

    let getConsultants = () => {
        if (selectedConsultant) {
            return [selectedConsultant];
        }

        let consultants = [];
        if (data && data.consultants) {
            _.forEach(data.consultants, s => {
                if (appointmentType === 1 || !!s.available || s.onlineBooking === "no") {
                    if (selectedSearchTypeForConsultant === 2) {
                        if (filterNameTextValue === "" || s.fullName.toLowerCase().includes(filterNameTextValue.toLowerCase())) {
                            consultants.push(s);
                        }
                    } else {
                        consultants.push(s);
                    }
                }

                if (appointmentType === 1) {
                    consultants = _.sortBy(consultants, ["distance"]);
                }
            });
        }

        return consultants;
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    let replaceElement = what => {
        const search = escapeRegExp(filterTextValue);
        const searchRegExp = new RegExp(`(?:${search})`, 'ig');

        return what.replace(searchRegExp, "<b>$&</b>");
    }
    let selectSpecialty = (specialty) => {
        return () => {
            setFilterPostcodeTextValue(data.postal);
            setFilterNameTextValue("");
            selectedSubSpecialtyCleared();
            setSelectedSpecialty(specialty);
            if (specialtySelectedChange) {
                specialtySelectedChange(specialty);
            }
            hideSelectorBox();

            stageSwitch(0);
        }
    }
    let selectSubSpecialty = (subSpecialty) => {
        return () => {
            setFilterPostcodeTextValue(data.postal);
            setFilterNameTextValue("");
            selectedConsultantCleared();
            setSelectedSubSpecialty(subSpecialty);
            if (subSpecialitySelectedChange) {
                subSpecialitySelectedChange(subSpecialty);
            }
            hideSelectorBox();

            stageSwitch(0);
        }
    }

    let loadMoreSlots = () => {
        // alert("load more!");
        if (consultantSelectedChange) {
            consultantSelectedChange(selectedConsultant, currentPageTimesToDisplay + 1);
            setCurrentPageTimesToDisplay(currentPageTimesToDisplay + 1);
        }
    }

    let selectConsultant = consultant => {
        return () => {
            setSelectedSlotDateData(null);
            setSelectedSlot(null);
            setSelectedConsultant(consultant);
            if (consultantSelectedChange) {
                setCurrentPageTimesToDisplay(0);
                consultantSelectedChange(consultant);
            }
            hideSelectorBox();

            setTimeout(() => {
                scrollIntoElement(refStage3.current, 10);
            }, 100);

            stageSwitch(3);
        }
    }

    let getPicture = gmc => {
        let founded = null;
        if (data.consultantsDetails) {
            for (let detail of data.consultantsDetails) {
                if (detail.GMCNumber === parseInt(gmc)) {
                    founded = detail;
                    break;
                }
            }
        }

        return founded;
    };

    let selectTimeSlot = (slot) => {
        return () => {
            // console.log("slot selected", slot);

            setSelectedSlot(slot);
            if (slotSelectedChange) {
                slotSelectedChange(slot);
            }
            hideSelectorBox();

            stageSwitch(5);
        }
    }

    let filteredPostcodeTextChanged = newFilterValue => {
        // console.log("newFilterValue", newFilterValue);
        // FIXME: should I debounce it ?
        setFilterPostcodeTextValue(newFilterValue);
        setSearchCriteriaChanged(true);
    }

    let filteredNameTextChanged = newFilterValue => {
        // console.log("newFilterValue", newFilterValue);
        // FIXME: should I debounce it ?
        setFilterNameTextValue(newFilterValue);
    }

    let filteredTextChanged = newFilterValue => {
        // console.log("newFilterValue", newFilterValue);
        // FIXME: should I debounce it ?
        setFilterTextValue(newFilterValue);
    }

    let replaceElementConsultant = what => {
        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        const search = escapeRegExp(",");
        const searchRegExp = new RegExp(`(?:${search})`, 'ig');

        return what.replace(searchRegExp, "<br\>");
    };

    const formatDateSelected = () => {
        if (selectedSlotDateData) {
            let m = moment(selectedSlotDateData.when);
            return m.format("dddd Do MMMM, YYYY");
        }

        return "";
    }
    const formatDateSelectedEx = () => {
        if (selectedSlotDateData && selectedSlot) {
            let m = moment(selectedSlotDateData.when);
            return m.format("dddd Do MMMM, YYYY") + "<br/>" + selectedSlot.fullHour;
        }

        return "";
    }

    let showSelectorBox = () => {
        setSelectorBoxVisible(true);
    }
    let hideSelectorBox = () => {
        setSelectorBoxVisible(false);
    }

    let selectedSpecialtyClick = () => {
        setFilterTextValue("");
        setVisibleSelector("specialty");
        showSelectorBox();
    }
    let selectedSpecialtyCleared = () => {
        setFilterPostcodeTextValue(data.postal);
        setFilterNameTextValue("");
        selectSpecialty(null)();
        setSelectedSpecialty(null);
        // selectSubSpecialty(null)();
        setSelectedSubSpecialty(null);
        setSelectedConsultant(null);
        setSelectedSlotDateData(null);
        setSelectedSlot(null);
    }

    let selectedSubSpecialtyClick = () => {
        setFilterTextValue("");
        setVisibleSelector("sub-specialty");
        showSelectorBox();
    }
    let selectedSubSpecialtyCleared = () => {
        setFilterPostcodeTextValue(data.postal);
        setFilterNameTextValue("");
        selectSubSpecialty(null)();
        setSelectedSubSpecialty(null);
        setSelectedConsultant(null);
        setSelectedSlotDateData(null);
        setSelectedSlot(null);
    }

    let searchAgain = () => {
        // console.log("searchAgain", selectedSpecialty, selectedSubSpecialty);
        refreshConsultants(filterPostcodeTextValue, appointmentType, appointmentDistance,
            () => {
                // show postal logic error !
                setPostalCodeErrorVisible(true);
            },
            () => {
            },
            () => {
                setSearchCriteriaChanged(false);

                setSelectedConsultant(null);
                stageSwitch(2);
            },
            selectedSpecialty, selectedSubSpecialty
        );
    }

    let stageSwitch = (stage) => {
        // if (currentStage !== stage) {
        //     if (stage === 0 && notClearSpecialityOnZero !== true) {
        //         selectedSpecialtyCleared();
        //     }
        // }
        setParentCurrentStage(stage);
    };

    let selectedConsultantClick = () => {
        stageSwitch(1);

        setTimeout(() => {
            scrollIntoElement(refStage2.current);
            searchAgain();
        }, 50);
        // scrollIntoElement(refStage2.current);

        /*
        setFilterTextValue("");
        // setFilterPostcodeTextValue("");
        // setFilterNameTextValue("");
        setVisibleSelector("consultant");
        showSelectorBox();
        */

        // scroll to ...
    }
    let selectedConsultantCleared = () => {
        setSelectedConsultant(null);
        setSelectedSlotDateData(null);
        setSelectedSlot(null);
    }

    let selectedSlotClick = () => {
        setFilterTextValue("");
        setVisibleSelector("slots");
        showSelectorBox();
    }
    let selectedSlotCleared = () => {
        setSelectedSlot(null);

        stageSwitch(4);
    }

    if (currentStage === 6) {
        return (
            <div className={"app-main-page-fv"}>
                <div className={"confirmation-window"}>
                    <div className={"confirm-info-box"}>
                        <div className={"confirm-label"}>
                            Appointment details
                        </div>

                        <div className={"confirm-message confirm-label"}>
                            {selectedConsultant.fullName}
                            <div className={"confirm-spec"}>{selectedSpecialty}</div>
                        </div>

                        <div className={"confirm-message-location1"}>{selectedConsultant.location1}</div>
                        <div className={"confirm-message-location2"}
                             dangerouslySetInnerHTML={{__html: replaceElementConsultant(selectedConsultant.regionalSites)}}/>

                        <div className={"confirm-message"}>
                            <b>
                                <div dangerouslySetInnerHTML={{__html: formatDateSelectedEx()}}></div>
                            </b>
                        </div>
                    </div>

                    {consultantAuthorization &&
                        <div className={"confirm-auth-code"}>
                            Authorisation Code: <span
                            className={"code"}>{consultantAuthorization.authorisationNo}</span>
                        </div>
                    }
                    {!consultantAuthorization &&
                        <div className={"confirm-auth-code"}>
                            Authorisation Code: <span
                            className={"code"}>0X123456789</span>
                        </div>
                    }
                    <div className={"confirm-summary"}>
                        <b>A new booking has been made for you.</b>
                        Please make sure you attend your appointment. You should expect to receive a confirmation email of this appointment in the next 24/48 hours.
                        <br/><br/>
                        Should you wish to cancel the appointment, this can be done on the portal through the "My appointments" tile.
                    </div>
                    <div className={"confirm-close-button"} style={{marginBottom: 100}} onClick={() => {
                        window.location = window.getGlobalConfig().home;
                    }}>
                        Close
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"app-main-page-fv"}>
            {/*<div className={"app-text-bold"}>*/}
            {/*    Please select the speciality you require, as indicated on your referral letter*/}
            {/*</div>*/}
            {/*
            <div className={"new-app-text-top"}>
                {currentStage > 0 &&
                    <div className={"go-back-stage"} onClick={() => {
                        setCurrentStage(currentStage - 1);
                    }}>
                        <FiChevronLeft size={"28"}/>
                    </div>
                }
                {currentStage === 0 &&
                    "Search for a specialty"}
                {currentStage === 1 &&
                    "Choose your specialist by"}
            </div>
            */}


            <div>
                <div className={"app-select-item-label"}>
                    <div className={"select-title"}>
                        Book your consultation
                    </div>
                    <div className={"select-description"}>
                        Please select the speciality as indicated on your referral letter
                    </div>
                </div>
                <div className={"app-select-item"}>
                    <ButtonBox
                        disabled={!data.specialities}
                        showClear={true}
                        clicked={selectedSpecialtyClick}
                        cleared={selectedSpecialtyCleared}
                        selectedItem={selectedSpecialty}
                        showChevron={true}
                        customHTML={
                            <div className={clsx({
                                "app-button-simple": true,
                                "app-button-back": !selectedSpecialty,
                                "app-button-clear": !!selectedSpecialty
                            })}>
                                {!selectedSpecialty &&
                                    <div className={"app-button-placeholder"}>Search specialities</div>
                                }
                                {!!selectedSpecialty &&
                                    <div className={"app-button-enabled"}>{selectedSpecialty}</div>
                                }
                            </div>
                        }
                    />
                </div>
                {((!data.subSpecialities && !data.consultants) || (data.subSpecialities && data.subSpecialities.length > 0)) &&
                    // {((data.subSpecialities && data.consultants) || (data.subSpecialities && data.subSpecialities.length > 0)) &&
                    <div className={"app-select-item"}>
                        <ButtonBox disabled={!data.subSpecialities || !selectedSpecialty}
                                   showClear={true}
                                   clicked={selectedSubSpecialtyClick}
                                   cleared={selectedSubSpecialtyCleared}
                                   readOnly={data.subSpecialities && data.subSpecialities.length === 1}
                                   selectedItem={selectedSubSpecialty}
                                   showChevron={true}
                                   customHTML={
                                       <div className={clsx({
                                           "app-button-simple": true,
                                           "app-button-back": !selectedSubSpecialty,
                                           "app-button-clear": !!selectedSubSpecialty
                                       })}>
                                           {!selectedSubSpecialty &&
                                               <div className={"app-button-placeholder"}>Select sub-speciality</div>
                                           }
                                           {!!selectedSubSpecialty &&
                                               <div className={"app-button-enabled"}>{selectedSubSpecialty}</div>
                                           }
                                       </div>
                                   }
                        />
                    </div>
                }

                {currentStage === 0 &&
                    <div className={"app-select-item"}>
                        <ButtonBox
                            // disabled={!data.consultants}
                            disabled={!(data.noSubSpecialty || selectedSubSpecialty) || !selectedSpecialty}
                            showClear={false}
                            clicked={selectedConsultantClick}
                            cleared={selectedConsultantCleared}
                            selectedItem={selectedConsultant}
                            customHTML={
                                <div className={clsx({
                                    "app-button-simple": true,
                                    "app-button-back blue": true,
                                    // "app-button-back blue": !selectedConsultant,
                                    // "app-button-clear": !!selectedConsultant
                                })}>
                                    <div className={"app-button-placeholder blue"}>Confirm</div>
                                </div>
                            }
                        />
                    </div>
                }
            </div>

            <div ref={refStage2} className={clsx("app-item-search-mn",currentStage < 1 ? "app-disabled-element" : "", "single-step-item")}>
                <div className={"app-select-item"}>
                    <div className={"app-item-specialty2"}>
                        <div className="specialty-textbox app-item-app-top2 with-util">
                            {selectedSearchTypeForConsultant === 1 &&
                                <Textbox
                                    placeholder={"Search by postcode"}
                                    value={filterPostcodeTextValue}
                                    clearClicked={() => {
                                        setFilterPostcodeTextValue("")
                                    }}
                                    changed={filteredPostcodeTextChanged}/>
                            }
                            {selectedSearchTypeForConsultant === 2 &&
                                <Textbox placeholder={"Search by name"}
                                         value={filterNameTextValue}
                                         clearClicked={hideSelectorBox}
                                         changed={filteredNameTextChanged}/>
                            }
                            <div className={"new-search-utils"}>
                                <div className={"search-switch"}>
                                    <div className={clsx({
                                        "search-switch-item": true,
                                        "selected": selectedSearchTypeForConsultant === 1
                                    })}
                                         onClick={() => {
                                             // setFilterPostcodeTextValue("");
                                             setSelectedSearchTypeForConsultant(1)
                                         }}
                                    >
                                        {selectedSearchTypeForConsultant !== 1 &&
                                            <div className={"search-switch-icon"}>
                                                <FiCircle color={"#CACACA"} size={20}/>
                                            </div>
                                        }
                                        {selectedSearchTypeForConsultant === 1 &&
                                            <div className={"search-switch-icon"}>
                                                <FaCheckCircle color={"#1899F5"} size={20}/>
                                            </div>
                                        }

                                        <div className={"search-switch-label"}>
                                            Postcode
                                        </div>
                                    </div>
                                    <div className={clsx({
                                        "search-switch-item": true,
                                        "selected": selectedSearchTypeForConsultant === 2
                                    })}
                                         onClick={() => {
                                             // setFilterNameTextValue("");
                                             setSelectedSearchTypeForConsultant(2)
                                         }}
                                    >
                                        {selectedSearchTypeForConsultant !== 2 &&
                                            <div className={"search-switch-icon"}>
                                                <FiCircle color={"#CACACA"} size={20}/>
                                            </div>
                                        }
                                        {selectedSearchTypeForConsultant === 2 &&
                                            <div className={"search-switch-icon"}>
                                                <FaCheckCircle color={"#1899F5"} size={20}/>
                                            </div>
                                        }

                                        <div className={"search-switch-label"}>
                                            Name
                                        </div>
                                    </div>
                                </div>

                                <div className={"consultant-filers"}>
                                    <div className={"consultant-filers-where"} onClick={() => {
                                        // setAppointmentType(appointmentType === 1 ? 0 : 1);
                                        setVisibleSelector("appointmentType");
                                        showSelectorBox();
                                    }}>
                                        <div className={"consultant-filers-label"}>
                                            Show
                                        </div>
                                        {appointmentType === 0 && "Next appointment near me"}
                                        {appointmentType === 1 && "All MyWay consultants"}
                                        <div className={"consultant-filers-button"}>
                                            <FiChevronDown/>
                                        </div>
                                    </div>
                                    <div className={"consultant-filers-far"} onClick={() => {
                                        setVisibleSelector("miles");
                                        showSelectorBox();
                                    }}>
                                        <div className={"consultant-filers-label"}>
                                            Within
                                        </div>
                                        {appointmentDistance} miles
                                        <div className={"consultant-filers-button"}>
                                            <FiChevronDown/>
                                        </div>
                                    </div>
                                </div>

                                <div className={"app-select-item"}>
                                    <ButtonBox
                                        showClear={false}
                                        clicked={searchAgain}
                                        selectedItem={selectedConsultant}
                                        disabled={!searchCriteriaChanged}
                                        customHTML={
                                            <div className={clsx({
                                                "app-button-simple": true,
                                                "app-button-back blue": true,
                                                "app-button-clear": false
                                            })}>
                                                <div className={"app-button-placeholder blue"}>Search</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"app-item-consultants"}>
                            {/*
                            {getConsultants().length === 0 &&
                                <div className={"no-consultants-found"}>No consultants found</div>
                            }
                            */}
                            {getConsultants().length > 0 && getConsultants().map(item => (
                                <div key={item._id["$oid"]}>
                                    <SingleConsultantPanel item={item}
                                                           selectable={true}
                                                           noPadding={true}
                                                           selectConsultantEvent={selectConsultant(item)}
                                                           consultantsDetails={data.consultantsDetails}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div ref={refStage3} className={clsx("app-item-calendar-main", currentStage < 3 ? "app-disabled-element" : "", "single-step-item")}>
                <div className={"app-select-item sm-padding"}>
                    {(!!selectedConsultant && data.freeSlots) ?
                        <MonthCalendar slots={data.freeSlots}
                                       ignoreNotInMonth={true}
                                       consultantSelected={selectedConsultant}
                                       onLoadMore={loadMoreSlots}
                                       selectedDateExt={selectedSlotDateData}
                                       canLoadMore={currentPageTimesToDisplay < 1}
                                       onSlotSelect={(slotDateSelected) => {
                                           setSelectedSlot(null);
                                           setSelectedSlotDateData(slotDateSelected);
                                           stageSwitch(4);
                                       }}
                        />
                        :
                        <MonthCalendar ignoreNotInMonth={true} placeholder={true}/>
                    }
                </div>
            </div>

            <div ref={refStage4} className={clsx(currentStage < 3 ? "app-disabled-element" : "", "single-step-item")}>
                <div className={"app-select-item"}>
                    <ButtonBox disabled={!selectedSlotDateData}
                               showClear={true}
                               clicked={selectedSlotClick}
                               cleared={selectedSlotCleared}
                               selectedItem={selectedSlot}
                               showChevron={true}
                               customHTML={
                                   <div className={clsx({
                                       "app-button-simple": true,
                                       "app-button-back": !selectedSlot,
                                       "app-button-clear": !!selectedSlot
                                   })}>
                                       {!selectedSlot &&
                                           <div className={"app-button-placeholder"}>Select a time slot</div>
                                       }
                                       {!!selectedSlot &&
                                           <div className={"app-button-enabled"}>{selectedSlot.fullHour}</div>
                                       }
                                   </div>
                               }
                    />
                </div>
            </div>

            <div className={clsx(currentStage < 4 ? "app-disabled-element" : "", "single-step-item")}>
                {!selectedConsultant &&
                    <div className={"app-summary"}>
                    </div>
                }
                {selectedConsultant &&
                    <div className={"app-summary"}>
                        <div className={"app-summary-full-name"}>
                            {selectedConsultant.fullName}
                        </div>
                        {/*{formatDateSelectedEx()}*/}
                        <div className={"app-summary-speciality"}>
                            {selectedConsultant.speciality}
                        </div>

                        <div className={"app-summary-location"}>
                            {selectedConsultant.location1}
                        </div>
                        <div className={"app-summary-regional"}>
                            {selectedConsultant.regionalSites}
                        </div>

                        <div className={"app-summary-when"}>
                            <div dangerouslySetInnerHTML={{__html: formatDateSelectedEx()}}></div>
                            {/*{formatDateSelectedEx()}*/}
                        </div>

                        {/*<div className={"confirm-message-location2"}*/}
                        {/*     dangerouslySetInnerHTML={{__html: replaceElementConsultant(selectedConsultant.regionalSites)}}/>*/}

                        <div className={"app-summary-confirm"}>
                            <ButtonBox
                                // disabled={!data.consultants}
                                disabled={!selectedSlot}
                                showClear={false}
                                // clicked={selectedConsultantClick}
                                clicked={bookNow}
                                // cleared={selectedConsultantCleared}
                                selectedItem={selectedConsultant}
                                customHTML={
                                    <div className={clsx({
                                        "app-button-simple": true,
                                        "app-button-back blue": true,
                                        // "app-button-back blue": !selectedConsultant,
                                        // "app-button-clear": !!selectedConsultant
                                    })}>
                                        <div className={"app-button-placeholder blue"}>Confirm booking</div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                }
            </div>

            {/*
            <div>
                {[...Array(100)].map((x, i) =>
                    <div key={i}>AAAA: {i}</div>
                )}
            </div>
            */}

            {/*
            <div className={"app-select-item"}>
                <ButtonBox disabled={!selectedSlotDateData}
                           showClear={true}
                           clicked={selectedSlotClick}
                           cleared={selectedSlotCleared}
                           selectedItem={selectedSlot}
                           customHTML={
                               <div className={clsx({
                                   "app-button-simple": true,
                                   "app-button-back blue": !selectedSlot,
                                   "app-button-clear": !!selectedSlot
                               })}>
                                   {!selectedSlot &&
                                       <div className={"app-button-placeholder blue"}>Select a time slot</div>
                                   }
                                   {!!selectedSlot &&
                                       <div className={"app-button-enabled"}>{selectedSlot.fullHour}</div>
                                   }
                               </div>
                           }
                />
            </div>

            <div className={clsx({"page-submit-button": true, "disabled": !selectedSlot})}
                 onClick={bookNow}>
                Book now
            </div>
            */}

            <div className={"app-modal-message"} style={{
                display: finalInformationVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label error"}>
                            No availability
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setFinalInformationVisible(false);
                                selectedSpecialtyCleared();

                                // move to start ...
                                scrollIntoElement(null);
                                stageSwitch(0);
                            }}>
                                <img src={"/close2.png"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        We are sorry that there is no specialist consultant available within the next 3 weeks for a
                        MyWay appointment.
                        <br/><br/>
                        Please call our bookings team on&nbsp;
                        <a className={"phone-call"} href="tel:08000928924"><b>0800 092 8924</b></a>
                        , who may be able to help you with this booking.
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>

            <div className={"app-modal-message"} style={{
                display: messageBoxVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label error"}>
                            Oops something went wrong.
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setMessageBoxVisible(false);
                            }}>
                                <img src={"/close2.png"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        Alternatively you can contact our bookings team on <a className={"phone-call"}
                                                                              href="tel:080045733746"><b>0800 4573
                        3746</b></a>, they may be able to
                        book your MyWay specialist appointment for you.
                    </div>
                    {/*<div className={"message-button"} onClick={() => {
                        setMessageBoxVisible(false);
                    }}>
                        Close
                    </div>*/}
                </div>
                <div className={"message-stretch"}></div>
            </div>

            <div className={"app-modal-message"} style={{
                display: postalCodeErrorVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label error"}>
                            Wrong post code
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setPostalCodeErrorVisible(false);
                            }}>
                                <img src={"/close2.png"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        Sorry, we can't find the postcode you provided. Please try another one.
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>

            <div className={"app-big-modal-selector-back"} style={{display: selectorBoxVisible ? "block" : "none"}}/>
            <div className={"app-big-modal-selector"} style={{
                visibility: selectorBoxVisible ? "visible" : "hidden",
                top: selectorBoxVisible ? "0" : "100vh"
            }}>
                {/* Confirmation for booking */}
                {visibleSelector === "confirmation" &&
                    <div className={"confirmation-window"}>
                        <div className={"confirm-image"}>
                            <img src={"/check.png"}/>
                        </div>
                        <div className={"confirm-label"}>
                            Confirmed
                        </div>
                        <div className={"confirm-message"}>
                            Appointment with<br/>
                            {selectedConsultant.fullName}<br/>
                            <b>{formatDateSelectedEx()}</b>
                        </div>
                        <div className={"confirm-message-location1"}>{selectedConsultant.location1}</div>
                        <div className={"confirm-message-location2"}
                             dangerouslySetInnerHTML={{__html: replaceElementConsultant(selectedConsultant.regionalSites)}}/>
                        {consultantAuthorization &&
                            <div className={"confirm-auth-code"}>
                                Authorisation Code: <span
                                className={"code"}>{consultantAuthorization.authorisationNo}</span>
                            </div>
                        }
                        {!consultantAuthorization &&
                            <div className={"confirm-auth-code"}>
                                Authorisation Code: <span
                                className={"code"}>0X123456789</span>
                            </div>
                        }
                        <div className={"confirm-summary"}>
                            Please make sure you attend your appointment. You should expect to receive a confirmation
                            email of this appointment in the next 24/48 hours.
                            <br/><br/>
                            Should you wish to cancel the appointment, this can be done on the portal through the "My
                            appointments" tile.
                        </div>
                        <div className={"confirm-close-button"} style={{marginBottom: 100}} onClick={() => {
                            window.location = window.getGlobalConfig().home;
                        }}>
                            Return to dashboard
                        </div>
                    </div>
                }
                {/* Specialty selector */}
                {visibleSelector === "specialty" &&
                    <div className={"app-item-specialty"}>
                        <div className="specialty-textbox app-item-app-top">
                            <Textbox placeholder="Example 'Orthopaedic'"
                                     clearClicked={hideSelectorBox}
                                     changed={filteredTextChanged}/>
                        </div>
                        <div className={"app-item-app-rest"}>
                            {(data && data.specialities) &&
                                <div className="specialty-founded">
                                    {getSpecs().map(item => (
                                        <div key={item} className="specialty-item" onClick={selectSpecialty(item)}>
                                            <div dangerouslySetInnerHTML={{__html: replaceElement(item)}}/>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                }

                {/* Sub specialty selector */}
                {visibleSelector === "sub-specialty" &&
                    <div className={"app-item-specialty"}>
                        <div className="specialty-textbox app-item-app-top">
                            <Textbox placeholder="Enter quick filter"
                                     clearClicked={hideSelectorBox}
                                     changed={filteredTextChanged}/>
                        </div>
                        <div className={"app-item-app-rest"}>
                            {(data && data.subSpecialities) &&
                                <div className="specialty-founded">
                                    {getSubSpecs().map(item => (
                                        <div key={item} className="specialty-item" onClick={selectSubSpecialty(item)}>
                                            <div dangerouslySetInnerHTML={{__html: replaceElement(item)}}/>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                }

                {/* Slots */}
                {visibleSelector === "slots" &&
                    <div className={"app-item-specialty"}>
                        <div className="specialty-textbox app-item-app-top">
                            <img src={"/close.png"} onClick={() => hideSelectorBox()}/>
                            Please select your time slot
                        </div>
                        <div className={"app-item-app-rest"}>
                            <div className="specialty-founded">
                                {getSlotHours().map((item, i) => (
                                    <div key={item.identifier} className="specialty-item centered"
                                         onClick={selectTimeSlot(item)}>
                                        {/*<div dangerouslySetInnerHTML={{__html: replaceElement(item)}}/>*/}
                                        {item.fullHour}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {visibleSelector === "appointmentType" &&
                    <div className={"app-item-specialty"}>
                        <div className="specialty-textbox app-item-app-top">
                            <img src={"/close.png"} onClick={() => hideSelectorBox()}/>
                            Please select search criteria
                        </div>
                        <div className={"app-item-app-rest"}>
                            <div className="specialty-founded">
                                {["Next appointment near me", "All MyWay consultants"].map((item, i) => (
                                    <div key={"a-number" + i} className="specialty-item centered"
                                         onClick={() => {
                                             setAppointmentType(i);
                                             setSearchCriteriaChanged(true);
                                             hideSelectorBox();
                                         }}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {visibleSelector === "miles" &&
                    <div className={"app-item-specialty"}>
                        <div className="specialty-textbox app-item-app-top">
                            <img src={"/close.png"} onClick={() => hideSelectorBox()}/>
                            Please select distance within
                        </div>
                        <div className={"app-item-app-rest"}>
                            <div className="specialty-founded">
                                {[5, 10, 20, 30].map((item, i) => (
                                    <div key={"a-number" + i} className="specialty-item centered"
                                         onClick={() => {
                                             setAppointmentDistance(item);
                                             setSearchCriteriaChanged(true);
                                             hideSelectorBox();
                                         }}>
                                        {/*<div dangerouslySetInnerHTML={{__html: replaceElement(item)}}/>*/}
                                        {item} miles
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {/* Consultant selector */}
                {/*{visibleSelector === "consultant" &&
                    <div className={"app-item-specialty2"}>
                        <div className="specialty-textbox app-item-app-top2 with-util">
                            {selectedSearchTypeForConsultant === 1 &&
                                <Textbox placeholder={data.postalPatient}
                                         value={filterPostcodeTextValue}
                                         clearClicked={hideSelectorBox}
                                         changed={filteredPostcodeTextChanged}/>
                            }

                            {selectedSearchTypeForConsultant === 2 &&
                                <Textbox placeholder={"Search by name"}
                                         value={filterNameTextValue}
                                         clearClicked={hideSelectorBox}
                                         changed={filteredNameTextChanged}/>
                            }

                            <Textbox placeholder={selectedSearchTypeForConsultant === 1 ? data.postal : "enter name"}
                                     clearClicked={hideSelectorBox}
                                     changed={filteredTextChanged}/>


                            <div className={"search-utils"}>
                                <div className={"search-switch"}>
                                    <div className={clsx({
                                        "search-switch-item": true,
                                        "selected": selectedSearchTypeForConsultant === 1
                                    })}
                                         onClick={() => {
                                             // setFilterPostcodeTextValue("");
                                             setSelectedSearchTypeForConsultant(1)
                                         }}
                                    >
                                        Postcode
                                    </div>
                                    <div className={clsx({
                                        "search-switch-item": true,
                                        "selected": selectedSearchTypeForConsultant === 2
                                    })}
                                         onClick={() => {
                                             // setFilterNameTextValue("");
                                             setSelectedSearchTypeForConsultant(2)
                                         }}
                                    >
                                        Name
                                    </div>
                                </div>
                                <div onClick={() => {
                                    if (selectedSearchTypeForConsultant === 1) {
                                        refreshConsultants(filterPostcodeTextValue,
                                            () => {
                                                // show error !
                                                setPostalCodeErrorVisible(true);
                                            }
                                        );

                                    }
                                }
                                } className={clsx({
                                    "search-button": true,
                                    "disabled": selectedSearchTypeForConsultant === 2
                                })}>
                                    Search
                                </div>
                            </div>
                        </div>
                        <div className={"app-item-app-rest"}>
                            {getConsultants().length === 0 &&
                                <div className={"no-consultants-found"}>No consultants found</div>
                            }
                            {getConsultants().length > 0 && getConsultants().map(item => (
                                <div key={item._id["$oid"]}>
                                    <SingleConsultantPanel item={item}
                                                           selectable={true}
                                                           selectConsultantEvent={selectConsultant(item)}
                                                           consultantsDetails={data.consultantsDetails}/>
                                </div>
                            ))}
                        </div>
                    </div>
                }
*/}
                {/* Confirm booking / or cancel ? */}
                {visibleSelector === "book-now" &&
                    <div className={"app-item-summary"}>
                        {/*
                        <div className={"top_image"}>
                            <img src={"/top_image.png"}/>
                        </div>
                        */}

                        <div className={"center-info"}>
                            Please confirm the following appointment:
                        </div>

                        {selectedConsultant &&
                            <div className={"consultant-summary"}>
                                <div className={"consultant-summary-split"}>
                                    {getPicture(selectedConsultant.gmcNumberBase) !== null &&
                                        <div className={"consultant-panel-item1"}>
                                            <div className={"consultant-image"}>
                                                <div>
                                                    <img className={"consultant-image-img"}
                                                         src={`data:image/jpeg;base64,${getPicture(selectedConsultant.gmcNumberBase).imgData}`}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {getPicture(selectedConsultant.gmcNumberBase) === null &&
                                        <div className={"consultant-panel-item1"}>
                                            <div className={"consultant-image"}>
                                                <div>
                                                    <img className={"consultant-image-img"}
                                                         src="/img_placeholder.png"/>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className={"consultant-panel-item2"}>
                                        <div className={"consultant-panel-full-name"}>
                                            {selectedConsultant.fullName}
                                        </div>
                                        <div className={"consultant-panel-info-line1 with-spacing"}>
                                            {selectedConsultant.speciality}
                                        </div>
                                        {/*<div className={"consultant-panel-info-line2 with-spacing"}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: replaceElementConsultant(selectedConsultant.regionalSites)}}/>
                                    </div>*/}
                                    </div>
                                </div>

                                <div className={"consultant-panel-item3"}>
                                    <div className={"consultant-panel-full-name"}>
                                        Address
                                    </div>
                                    <div className={"consultant-panel-info-line2 with-spacing with-big-font"}>
                                        <div
                                            className={"consultant-panel-location"}>{selectedConsultant.location1}</div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: replaceElementConsultant(selectedConsultant.regionalSites)}}/>
                                    </div>
                                </div>

                                <div className={"consultant-panel-item4"}>
                                    <div className={"consultant-panel-full-name"}>
                                        Date
                                    </div>
                                    <div className={"consultant-panel-info-line2 with-spacing with-big-font bolder"}>
                                        {formatDateSelected()}
                                    </div>
                                </div>

                                <div className={"consultant-panel-item4"}>
                                    <div className={"consultant-panel-full-name"}>
                                        Time
                                    </div>
                                    <div className={"consultant-panel-info-line2 with-spacing with-big-font bolder"}>
                                        {selectedSlot ? selectedSlot.fullHour : ""}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={"buttons-confirmation"}>
                            <div className={"cancel-button"} onClick={cancelConfirmation}>
                                Cancel
                            </div>
                            <div className={"confirm-button"} onClick={confirmConfirmation}>
                                Confirm
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MainPage;
